export default
    {
        //(Please Do Not Remove The comma(,) after every variable)
        //Change The Website Template
        name :'Justin Hendrickson',
        headerTagline: [//Line 1 For Header
                        'Hi 👋 You found me! I am Justin Hendrickson.',
                        //Line 2 For Header
                        'Software Developer',
                        //Line 3 For Header
                        // 'from India'
                        ''
    ],
        //Contact Email
        contactEmail:'jhendrickson345@gmail.com',
        // Add Your About Text Here
        abouttext: "I love coding",
        aboutImage:'https://lh3.googleusercontent.com/T9kiVStjCcwJz8TNlnaLuvotWBUQfRJevy4F31ie9mxnFqa0sYirhM7tbTikEk1wVmg9BM_gCarggw_N1MPkuDuq5XAyUhOFsyRAwau-bW8bJF3LAX3xmQlmuqR2VlRoJ3Rhu0lM4zSm_d5ea41pw4iRINrUKXTf7Vm9u3MXu9R5XYVLNrAgz-cjkiBm0U4p2cgQy7lQwB4GQSdwUQkrfohN0qo1OsUvy8Xs_zC058JggQLg37Dmubh9wtwIC3qReGQm7cUQUnTfiE9iHIM5W1MWeHnwJL7pfUVeUHsSBFpaEJk95Mh34Z6KMLZmpkF3rDxreWPzpR6L2okG992nWO-NrMRLzUoONzysobaevo6zxowOEUyVE1z7jaOm3Jz8VZWoDFn9r2_v8jJ8M1N75-UpeYBwXY5a6QSZ43nte4i1OwUTuVLD2eMvvBxhTuwSGEdVwjfALgbIE0nUKglYPNapSusai12HFsEIwNFcPsgfVs9hhMr9UqBjRJu4_sv0MeKzj246GURwk1tLyPk6kBVZT3hNV5yLiXSd5B2v7ugM4cHgVuMGJjHFII44o5aEZan2Pzc0hRZ0F5U2_5EYwfPmjvjJ_DtxGLb2uqgXd6YoSO48mfGi-XaBrF2v-T97Y6QojbrrQczxmJwm4AqnIU6VTyjModQkarH57iOG6zqGCkljVdOeu3nk4WhpJg=s625-no?authuser=0',
       //Change This To Hide The Image of About Section (True Or False)
       ShowAboutImage:true,//true or false (Change Here)
       // Change Projects Here 
       projects:[
           {
            id: 1,//DO NOT CHANGE THIS (Please)😅
            title:'Resume', //Project Title - Add Your Project Title Here
             service:'', // Add Your Service Type Here
            //Project Image - Add Your Project Image Here
             imageSrc:"https://www.stockvault.net/data/2018/08/13/253769/preview16.jpg",
             //Project URL - Add Your Project Url Here
             url:'https://justin-hendrickson.now.sh/'
            },
            {
                id: 2,//DO NOT CHANGE THIS (Please)😅
                title: 'Github',
                service: 'Coding',
                imageSrc: "https://images.pexels.com/photos/270360/pexels-photo-270360.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
                url: 'https://github.com/justintunev7'
            },
        //     { 
        //         id: 3,//DO NOT CHANGE THIS (Please)😅
        //         title: 'Project Three',
        //         service: 'Web App',
        //         imageSrc: "https://images.unsplash.com/photo-1511500118080-275313ec90a1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
        //         url: 'http://chetanverma.com/'
        //     },
        //     {
        //         id: 4,//DO NOT CHANGE THIS (Please)😅
        //         title: 'Project Four',
        //         service: 'Branding',
        //         imageSrc: "https://images.unsplash.com/photo-1558452919-08ae4aea8e29?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
        //         url: 'http://chetanverma.com/'
        //    }

                    /*

                    If You Want To Add More Project just Copy and Paste This At The End (Update the id Respectively)
                ,{
                id: 5,
                title: 'Project Five',
                service: 'Something Amazing',
                imageSrc: "",
                url: ''
            }
                */
        ],
        social: [
            // Add Or Remove The Link Accordingly
            {   name:'Github',
                url:'https://github.com/justintunev7'},
            // {
            //     name: 'Behance',
            //     url: 'https://www.behance.net/chetanverma'
            // },
            // {
            //     name: 'Dribbble',
            //     url: 'https://dribbble.com/chetanverma'
            // },
            // {
            //     name: 'Instagram',
            //     url: 'https://www.instagram.com/cv.uidesign/'
            // }

        ]
    }